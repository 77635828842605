<template>

  <div class="wrapper"  style="padding-top: 6em;">

    <div class="body mt-1">

      <div class="search-form form-inline m-2 w-100">
        <div class="float-left" style="width: 75%">
          <input v-model="to_search" class="form-control mr-sm-2 w-100" id="search-input" name="keyword" type="text" placeholder="Search..." aria-label="Search" style="border-bottom: 1px solid var(--black);background-image: none !important;">
        </div>
        <div class="float-right"  style="width: 25%">
          <button class="btn btn-sm btn-primary my-2 my-sm-0" style="height: 3.2em;" @click="search">Search</button>
        </div>
      </div>

      <div class="container">

        <div class="row small">
          <div class="col-12 pl-1">Search Results</div>
        </div>

      </div>

        <Games v-bind:sport="sport_id" v-bind:search="keywords" v-bind:searchable="searchable"></Games>
        <BottomFooter></BottomFooter>
        <bottom-navigation active-bets="0" page="search"></bottom-navigation>

    </div>

  </div>

</template>

<script>
import BottomNavigation from './BottomNavigation'
import Games from './Games'
import BottomFooter from './BottomFooter'

//import Menu from './Menu'

export default {
  name: 'Search',
  components: {
    BottomNavigation,
    Games,
    BottomFooter
  },
  data: function (){

    return {
      sport_id: 1,
      searchable: true,
      to_search: '',
      keywords: '',
      sub_page: '',
      results: '',
      busy: false,
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","search");
    // reset game filter
    //this.$store.dispatch("resetAllGames");

  },
  computed: {
    betslip_count: function (){

      return this.$store.state.betslip.total

    },
    current_sub_page: function () {

      return this.$store.state.current_sub_page;
    },
    current_page: function () {

      return this.$store.state.current_page;
    }
  },
  methods: {
    getKey: function(fixture,index){

      if(Array.isArray(fixture)) {

        var currentFixture = fixture[0];

      } else {

        currentFixture = fixture

      }

      var prefix = currentFixture === undefined || currentFixture.match_id == undefined ? index : currentFixture.match_id;
      return Math.random().toString(10).replace('0.', 'fixture-id-'+prefix + '-');

    },

    setSubPage: function (page){

      this.$store.dispatch("setCurrentSubPage",page);
      this.sub_page = page;

    },
    geSubPageActiveClass: function (subpage) {

      return subpage === this.sub_page ? 'active' : "";

    },
    setSport: function(sportID) {

      this.$store.dispatch("setSportID",sportID);

    },
    search: function () {
      var vm = this;
      vm.keywords = vm.to_search;
    },

  }
}
</script>